<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div style="height: 100vh;">
      <div class="container mt-3">
        <router-link class="btn btn-secondary" to="/drivers">
          <i class="fas fa-angle-double-left"></i> Volver a Conductores
        </router-link>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-8 col-md-8 col-sm-8">
            <h1 class="card-title">{{ driverName }}</h1>
            <h3 class="email-title2">
              <i class="fas fa-phone" style="margin-right: 5px;"></i>
              Telefono: {{ driverPhone }}
            </h3>
            <h3 class="email-title">
              <i class="fas fa-envelope" style="margin-right: 5px;"></i>
              Correo Electronico: {{ driverEmail }}
            </h3>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <img
              v-if="driverPicture2"
              :src="driverPicture2"
              class="card-img-top foto perfil"
            />
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 form-group">
            <p class="email-title">
              Dirección:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="direccion"
                id="direccion"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Numero de Licencia:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="numLicence"
                id="numLicencia"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Fecha de Expiración:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="expDate"
                id="fechaExpiracion"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Marca de Vehiculo:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="marca"
                id="marca"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Modelo de Vehiculo:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="modelo"
                id="modelo"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Año de Vehiculo:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="year"
                id="year"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Color de Vehiculo:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="color"
                id="color"
              />
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 form-group">
            <p class="email-title">
              Tablilla de Vehiculo:
              <input
                class="form-control"
                disabled
                type="text"
                v-model="carlicensenumber"
                id="tablilla"
              />
            </p>
          </div>
          <div
            v-if="driverPicture"
            class="col-lg-6 col-md-6 col-sm-6 form-group"
          >
            <p class="email-title">
              Licencia de conducir:
              <img
                :src="driverPicture"
                class="mt-2 card-img-top foto licencia"
              />
              <!-- :src="driverPicture" -->
            </p>
          </div>
          <div v-if="carLicense" class="col-lg-6 col-md-6 col-sm-6 form-group">
            <p class="email-title">
              Licencia de vehículo:
              <img :src="carLicense" class="mt-2 card-img-top foto licencia" />
            </p>
          </div>
          <div v-if="criminal" class="col-lg-6 col-md-6 col-sm-6 form-group">
            <p class="email-title">
              Documento antecedentes penales:
              <img :src="criminal" class="mt-2 card-img-top foto fotoGrande" />
            </p>
          </div>
          <div v-if="carImage" class="col-lg-6 col-md-6 col-sm-6 form-group">
            <p class="email-title">
              Foto de Vehiculo:
              <img :src="carImage" class="mt-2 card-img-top foto fotoGrande" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
import NavBarAdmin from "./NavBarAdmin.vue";
export default {
  name: "DriverProfile",
  components: {
    NavBarAdmin
  },
  data() {
    return {
      driverInfo: null,
      driverName: null,
      driverPhone: null,
      driverPicture: null,
      driverEmail: null,
      driverLicense: null,
      carLicense: null,
      criminal: null,
      numLicence: null,
      expDate: null,
      direccion: null,
      marca: null,
      modelo: null,
      year: null,
      color: null,
      driverPicture2: null,
      carImage: null
    };
  },
  mounted() {
    if (Parse.User.current() === null) {
      this.$router.push("/HelloWorld");
    }

    this.getDriverInfo();
    this.carInfo();
  },
  methods: {
    getDriverInfo() {
      let query = new Parse.Query("_User");
      query.include("objectId");
      query.equalTo("objectId", this.$route.params.id);
      query.first().then(user => {
        this.driverName = user.get("fullName");
        this.driverPhone = user.get("phoneNumber");
        this.driverEmail = user.get("username");
        this.direccion = user.get("address");
        this.numLicence = user.get("driverLicenseNumber");

        if (user.get("driverLicensePicture").url()) {
          this.driverPicture = user.get("driverLicensePicture").url();
        }
        if (user.get("profilePic").url()) {
          this.driverPicture2 = user.get("profilePic").url();
        }
        if (user.get("licensePic").url()) {
          this.driverLicense = user.get("licensePic").url();
        }
        if (user.get("criminalRecord").url()) {
          this.criminal = user.get("criminalRecord").url();
        }
      });
    },
    carInfo() {
      let query = new Parse.Query("Car");
      query.equalTo(
        "user",
        Parse.Object.extend("_User")
          .createWithoutData(this.$route.params.id)
          .toPointer()
      );
      query.first().then(result => {
        if (result.get("licensePic") != null) {
          this.carLicense = result.get("licensePic").url();
        }
        if (result.get("carImage") != null) {
          this.carImage = result.get("carImage").url();
        }

        this.carlicensenumber = result.get("license");
        this.expDate = result.get("expDate");
        this.marca = result.get("make");
        this.modelo = result.get("model");
        this.year = result.get("year");
        this.color = result.get("color");
      });
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  color: black !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.foto {
  box-shadow: 0 8px 6px -6px black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  border-radius: 15px;
}

.perfil {
  width: 140px;
  height: 160px;
}

/** */

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}

.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
}

.card-title,
.card-body {
  font-family: "Montserrat", sans-serif;
  color: rgb(146, 146, 146);
}

.email-title {
  font-size: 13px;
  color: rgb(146, 146, 146);
  border-bottom: 1px solid rgb(189, 189, 189);
  padding-bottom: 20px;
}

.email-title2 {
  font-size: 13px;
  color: rgb(146, 146, 146);
}

.licencia {
  width: 90%;
  height: 250px;
}

.fotoGrande {
  width: 95%;
  height: 500px;
}

.licence-title {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}
</style>
