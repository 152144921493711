<template>
  <div>
    <DriverProfile></DriverProfile>
  </div>
</template>
<script>
import DriverProfile from "../../components/DriverProfile.vue";
export default {
  name: "DriversProfile",
  components: {
    DriverProfile
  }
};
</script>
